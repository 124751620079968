import { render, staticRenderFns } from "./Year.vue?vue&type=template&id=0bd0121f&scoped=true&"
import script from "./Year.vue?vue&type=script&lang=js&"
export * from "./Year.vue?vue&type=script&lang=js&"
import style0 from "./Year.vue?vue&type=style&index=0&id=0bd0121f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd0121f",
  null
  
)

export default component.exports